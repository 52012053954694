<template>
  <div class="content-box">
    <p>
      用户注册协议《用户注册服务协议》（以下称&ldquo;本协议&rdquo;）
      由联动优势电子商务有限公司（以下称&ldquo;本公司&rdquo;） 与所有使用联动优势相关服务的主体
      （以下称&ldquo;用户&rdquo;或&ldquo;您&rdquo;）签订。 本公司作为本平台的所有方，
      根据本协议及/或随时对其的修改向您提供本公司的各项服务。
    </p>
    <p><strong>重要提示：</strong></p>
    <p>
      <strong
        >1、在使用本公司研发的APP及相应支付服务前， 您应当认真阅读并遵守本协议以及本平台公示的各项服务规则、产品说明等。
        本平台公示的各项服务/产品规则、产品说明均是本协议不可分割的一部分，
        具有同等法律效力，与本协议相冲突的，以本协议为准。</strong
      >
    </p>
    <p>
      <strong
        >2、您务必审慎阅读并充分理解各本协议条款内容，特别是免除或者限制责任的条款、 争议解决和法律适用条款。
        免除或者限制责任的条款可能以加粗字体显示， 您应重点阅读。您使用本服务即视为已阅读并同意本协议的约束。</strong
      >
    </p>
    <p>
      <strong
        >3、您知晓并已经认识到当您勾选&ldquo;已阅读并同意&rdquo;后， 本协议即构成对双方具有约束力的法律文件。
        如您不同意本协议或其中任何条款的约定，请您立即停止注册流程，
        不要进行后续操作，包括但不限于不接受本协议，不使用本服务。
        您使用任何本公司服务即表示接受并同意遵守本协议的全部条款。 您还充分了解和认识到其具有以下风险：</strong
      >
    </p>
    <p><strong>（1）您密码泄露或您身份可能被仿冒；</strong></p>
    <p>
      <strong
        >（2）由于互联网上存在黑客恶意攻击的可能性，互联网服务器可能会出现故障及其他不可预测的因素，
        合同签署信息可能会出现错误或延迟；</strong
      >
    </p>
    <p><strong>（3）您的上网设备及软件系统与所提供的网上交易系统不相匹配，无法签署合同或合同签署失败；</strong></p>
    <p><strong>（4）如您或其代理人不具备一定网上交易经验，可能因操作不当造成无法签署合同或合同签署失败；</strong></p>
    <p><strong>（5）您电脑系统感染电脑病毒或被非法入侵。</strong></p>
    <p>
      <strong
        >上述风险可能会导致您发生损失，您已经完全知晓并自愿承担上述风险，且承诺不因此而追究乙方的任何责任。</strong
      >
    </p>
    <p>
      <strong
        >4、您应自行认真阅读本公司公示的合同文本， 凡使用其登陆帐号、登陆密码、交易密码、
        数字证书等进行的签署电子合同的网上委托或其他行为均视为您本人的行为，
        该等行为一经发出即生效，且不得变更或撤销，由此所产生的一切后果均由您承担。</strong
      >
    </p>
    <p><strong>5、当本提示第3条列举的风险所指的事项发生时，由此导致的您损失，乙方不承担任何赔偿责任。</strong></p>
    <p>
      <strong
        >6、您同意，本公司有权根据需要不时地制订、修改本协议及/或各类规则，
        并以平台公示的方式进行变更公告，无需另行单独通知。
        变更后的协议和规则一经在平台公布后，立即或在公告明确的特定时间自动生效。
        若您在本协议内容公告变更生效后继续使用平台相关服务的，表示您已充分阅读、 理解并接受变更后的协议内容，
        也将遵循变更后的协议内容使用各项服务；
        若您不同意变更后的协议内容，您应在变更生效前停止使用平台相关服务。</strong
      >
    </p>
    <p>
      <strong
        >7、您承诺：
        您将本着合法、合理、善意的目的使用本服务，不利用本服务进行任何违法、侵害他人合法利益、恶意的行为。</strong
      >
    </p>
    <p>
      <strong
        >8、特别注意事项：&ldquo;我们&rdquo;仅向具备完全民事行为能力主体提供服务，如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人，同时立即停止相关注册流程，请您不要向&ldquo;我们&rdquo;提供任何个人信息，也不要使用任何服务。一经发现，我们有权拒绝提供全部服务，注销相关账户，也不会留存您提供的任何信息。
        若您非中华人民共和国境内（为本协议之目的，不包括香港、
        澳门特别行政区及台湾地区）用户，您还需同时遵守您所属国家或地区的法律， 且您确认，订立并履行本协议不违反您所属、
        所居住或开展经营活动或其他业务的国家或地区的法律法规。</strong
      >
    </p>
    <p>双方根据中华人民共和国相关法律、法规及有关规范性文件，就您使用乙方提供的支付服务有关事项达成如下约定：</p>
    <p><strong>一、定义</strong></p>
    <p>如无特别说明，下列术语在本协议中的定义为：</p>
    <p>
      1、联动大pOS平台（以下简称&ldquo;平台&rdquo;）： 是指商户通过该平台提交相应资料和信息、发起签约、
      申请开通本公司提供的各项服务、对用户号进行管理等， 具体功能以该平台实际提供的为准，
      且该平台以及相关服务将根据本公司的需要进行调整和增减。
    </p>
    <p>
      2、联动账户（或称&ldquo;账户&rdquo;）：指在您取得用户标识并完成通过身份验证后， 我们为您开立的支付账户。
      如您未完成身份验证，则不能使用余额服务（下文定义）。
    </p>
    <p>
      3、平台登录帐号：又称用户平台登录帐号，指您凭以登录本平台， 操作本公司提供用户号的帐号，您成功注册并完成激活后，
      本公司为您分配相应的用户号，您可使用登录帐号（即管理员帐号） 以及您设置的密码进行登录，
      任何主体在成功登录本平台后， 均可操作本平台功能和与其关联的用户号内的资金。
    </p>
    <p>
      4、用户号：指本公司为您配置的用来记载您的身份信息、交易信息、资金余额， 您凭以发起交易指令的电子簿记。
      本公司提供的用户号将与您提供的合法银行账户绑定，
      您的银行账户将根据您的交易指令和用户号的交易情况做相应的资金扣划和归集。
    </p>
    <p>
      5、身份要素：指我们用于识别您身份的信息要素，包括但不限于您的用户标识、联动账户、密码、数字证书、短信校验码、支付盾、电话号码、手机号码、身份证件号码及生物识别信息（如指纹信息、人脸信息、声纹信息等）等信息。
    </p>
    <p>
      6、有权机关：指依照法律法规等有权要求采取查询、冻结或扣划资金等措施的单位，包括但不限于公安机关、检察院、法院、海关、税务机关等。
    </p>
    <p>
      7、有权机关冻结：指按照前述有权机关要求进行的冻结。被冻结余额不能用于支付、提现或转账等，被冻结账户可能无法登录、使用。
    </p>
    <p>
      8、限制：指除有权机关冻结情况之外，用户标识或账户部分或全部功能不能使用，以及用户标识或账户相关资产、账户余额、第三方提供的授信额度不能使用。
    </p>
    <p>9、止付：指联动账户余额不能使用的限制措施，例如不能用于支付、提现或转账等服务。</p>
    <p>
      10、余额服务：指基于联动账户余额可以使用的充值、消费、收款、转账等服务。除本协议另有规定外，余额服务的功能及收付款额度将按照《非银行支付机构网络支付业务管理办法》及其他监管规定进行调整。
    </p>
    <p>
      11、收费：在使用本公司提供的服务时，会向您收取相关服务费用，费用标准以在服务开通时页面展示的为准，同时您同意，本公司将采用坐扣方式收取服务费用。
    </p>
    <p><strong>二、注册相关</strong></p>
    <p>
      您应当使用本人的电子邮箱或者本公司允许的其它方式进行注册， 并自行为平台登录帐号设置密码。 您注册成功后，
      您应当使用您设置的登录帐号和密码进行登录，并妥善保管相关帐号、密码信息；
      您应当对以您的帐号、密码登陆后进行的所有活动和事件负法律责任。您同意：
    </p>
    <p>
      1、平台登录帐号的所有权归本公司所有， 您完成注册申请流程即可使用本公司提供的平台登录帐号。
      您还可以进一步申请并通过验证后取得联动账户，使用更多服务。 同时，您应提供及时、详尽及准确的资料，
      并不断更新注册资料，符合及时、详尽准确的要求， 因您未及时更新资料导致的一切后果，均应由您自行承担。
      在您按照本公司要求提供相关资料、 信息并通过审核完方可使用本公司各项服务。
    </p>
    <p>
      2、若本公司有合理理由怀疑您提供的身份信息或相关资料错误、 不实、过时或不完整的，
      本公司有权暂停或终止向您提供部分或全部服务。 如果因您注册信息不真实而引起的问题及其产生的后果，
      本公司不承担任何责任，您将承担因此产生的任何直接或间接支出。
    </p>
    <p>
      3、您应对登录账号的创建、授权、删除等事项尽管理职责。
      登录账号在本平台中的一切操作同样视为您的行为，由您承担所有责任。
    </p>
    <p>
      4、您不得将登录帐号、密码转让或出借予他人使用。 如您发现帐号遭他人非法使用，应立即通知本公司。
      因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用， 本公司不承担任何责任。
      本公司不能也不会对因您未能遵守本款约定而发生的任何损失、
      损毁及其他不利后果负责。您理解本公司对您的请求采取行动需要合理期限，
      在此之前，本公司对已执行的指令及（或）所导致的您的损失不承担任何责任。
    </p>
    <p>
      5、您同意，基于运行和交易安全的需要， 本公司可以暂时停止提供或者限制各项服务部分功能，
      或提供新的功能，在任何功能减少、增加或者变化时， 只要您仍然使用本服务，表示您仍然同意本协议或者变更后的协议。
    </p>
    <p>
      6、本公司有权了解您使用本公司各项服务的真实交易背景及目的， 您应如实提供本公司所需的真实、全面、
      准确的信息或资料； 如果本公司有合理理由怀疑您提供虚假交易信息的，
      本公司有权暂时或永久限制您所使用服务的部分或全部功能。
    </p>
    <p><strong>三、注销相关</strong></p>
    <p>在需要终止使用本服务时，您可以申请注销您的平台帐号，您同意：</p>
    <p>1、如您需要注销您的平台登录帐号或账户，您应当依照本公司规定的流程进行注销。</p>
    <p>
      2、平台登录帐号或账户注销将导致本公司终止为您提供本服务， 本协议约定的双方的权利义务终止
      （依本协议其他条款另行约定不得终止的或依其性质不能终止的除外），
      同时您还仍应对您在注销平台登录帐号或账户前且使用本服务期间的行为承担相应责任， 包括但不限于可能产生的违约责任、
      损害赔偿责任及履约义务，同时本公司仍可保有您的相关信息。
    </p>
    <p>
      3、您申请注销的平台帐号或账户应当处于正常状态， 即您的平台登录帐号或账户的帐号信息和用户信息是最新、完整、正确的，
      且该帐号可以使用所有服务功能。 帐号信息或用户信息过时、缺失、
      不正确的帐号或被暂停或限制或终止提供服务的平台登录帐号不能被申请注销。
    </p>
    <p>
      4、您申请注销的平台登录帐号或账户应当不存在任何由于该帐号或账户被注销而导致的未了结的合同关系与其他基于该帐号的存在而产生或维持的权利义务，
      及本公司认为注销该帐号或账户会由此产生未了结的权利义务而产生纠纷的情况。
      如不符合前述任何情况的，您不能申请注销该帐号或账户。
    </p>
    <p>
      5、您理解并同意，如您有欺诈、发布或销售伪劣商品、 侵犯他人合法权益或其他严重违反网站规则的行为或其他违法行为的，
      本公司有权注销您名下的全部或部分登录帐号， 您将不能再用该帐号的登录名登录网站，所有服务将同时终止。
    </p>
    <p><strong>四、服务内容及使用规则</strong></p>
    <p>
      本公司提供的联动服务是我们向您提供的非金融机构支付服务， 是受您委托代您收付款的资金转移服务。
      收付款服务是指我们为您提供的代为收取或代为支付款项的服务。
    </p>
    <p>
      通过代为收取款项服务，您可以收到他人向您支付的款项。
      具体是指自您委托我们将您银行卡内的资金充值到您的联动账户或委托我们代为收取第三方向您支付的款项之时起，
      至根据您的指令将该等款项的全部或部分实际入账到您的银行账户或联动账户之时止 （含提现）的整个过程。
    </p>
    <p>
      通过代为支付款项服务，您可以支付款项给您指定的第三方。 具体是指自款项从您指定账户（非联动账户）出账之时起，
      至我们根据您或有权方给出的指令将上述款项的全部或部分入账到第三方的银行账户或联动账户之时止的整个过程；
      或自您根据本协议委托我们将您银行卡的资金划转到您或他人的联动账户或自您因委托我们代为收取相关款项并入账到您的联动账户之时起，
      至委托我们根据您或有权方给出的指令将上述款项的全部或部分入账到第三方的银行账户或联动账户之时止的整个过程。
    </p>
    <p>您向我们发出代为收付款项的指令后，非经法律程序或者非依本协议之约定，该指令不可撤销。</p>
    <p>收付款项服务的功能有如下几类：</p>
    <p>1、充值：您可以将您银行卡内的资金划转到您的联动账户。</p>
    <p>
      2、提现：您可以将您联动账户内余额划转至您名下的可接收款项的中华人民共和国境内
      （为本协议之目的，不含香港、澳门特别行政区及台湾地区）银行账户。
      除非您的联动账户被有权机关冻结、止付或采取其他限制措施，在符合收付款额度的前提下，
      我们将于收到提现指令后的合理时间内，将相应款项汇入该银行账户。为确保您的资金安全，
      我们可能会对提现进行风险审查，从而可能导致到账时间延迟。
    </p>
    <p>
      3、转账：您可以使用联动服务， 委托我们将款项转至收款方联动账户或可接收款项的中华人民共和国境内
      （为本协议之目的，不含香港、澳门特别行政区及台湾地区）银行账户。为确保您的资金和财产安全，
      您在使用转账服务时，请您谨慎核对收款方信息， 包括但不限于收款方的真实身份及准确的收款名等信息。
    </p>
    <p>4、积分：您可以通过登陆APP或使用相应服务来获取相应积分， 在进行消费交易或其他增值服务时用于抵扣相应的服务。</p>
    <p>
      身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作、
      发出的指令视为您本人做出。因您的原因造成的账户、密码等信息被冒用、
      盗用或非法使用，由此引起的风险和损失需要由您自行承担。 您同意：
    </p>
    <p>
      1、基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。
      例如您的联动账户在新设备首次登录的，我们可能通过密码加校验码的方式识别您的身份。
      并且为了进一步保障您的账户和资金安全，我们会帮助您联动账户登录的设备添加数字证书，
      确保您同时符合监管关于身份要素验证的相关要求。
    </p>
    <p>
      2、为了保障您的资金安全，请把手机及其他设备的密码设置成与用户标识及账户的密码不一致。
      如您发现有他人冒用或盗用您的用户标识、账户或者联动登录名及密码，
      或您的手机或其他有关设备丢失时，请您立即以有效方式通知我们；
      您还可以向我们申请暂停或停止联动服务。由于我们对您的请求采取行动需要合理时间，
      如我们未在合理时间内采取有效措施，导致您损失扩大的，我们将就扩大的损失部分承担责任，
      但我们对采取行动之前已执行的指令免于承担责任。
    </p>
    <p>3、用户标识和账户仅限您本人使用， 请勿转让、借用、赠与、继承，但联动账户内的相关财产权益可被依法继承。</p>
    <p>4、基于运行和交易安全的需要，我们可能会暂停或者限制联动服务部分功能，或增加新的功能。</p>
    <p>
      5、为了维护良好的网络环境，我们有时需要了解您使用联动服务的真实背景及目的，
      如我们要求您提供相关信息或资料的，请您配合提供。
    </p>
    <p>
      6、为了您的交易安全，在使用联动服务时，请您事先自行核实交易对方的身份信息 （如交易对方是否具有完全民事行为能力）
      并谨慎决定是否使用联动服务与对方进行交易。
    </p>
    <p>
      7、您有权利享受本公司在平台上提供的各项服务， 并有权利接受本公司提供服务时获得的技术支持、咨询等服务。
      具体服务内容由本公司根据实际情况提供， 您可以根据平台的引导进行选择使用具体的产品或服务并了解产品的说明及规则。
      当您开始使用产品及服务时， 即视为您已充分了解具体产品或服务的功能及各项规则说明，同意遵守并受其约束，
      相应产品或服务的规则说明亦是本协议的一部分，具有同等法律效力。
    </p>
    <p>
      8、您同意基于服务内容变化、法律及监管要求、运行和交易安全的需要， 本公司在没有提前通知您的情况下，
      可以新增新的服务，或停止提供或者限制部分服务功能。 对于功能的变更而导致的协议的变化， 本公司没有义务告知。
      只要您仍然使用本公司服务，表示您仍然同意本条款或者本条款修正后的条款。
    </p>
    <p>
      9、您不得对本服务任何部分通过本服务任何部分或使用本服务获得的任何内容，
      进行复制、拷贝、出售、转售或用于任何其它商业目的。
    </p>
    <p>
      10、您须对自己在使用服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，
      以及在本公司首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予本公司公司等额的赔偿。
    </p>
    <p>11、平台上相关服务的具体内容由本公司根据实际情况提供，本公司对其提供的服务拥有最终解释权。</p>
    <p>
      12、本公司需要定期或不定期地对提供平台或相关的设备进行检修或者维护，如因此类情况而造成平台服务在合理时间内的中断，
      本公司无需为此承担任何责任。 本公司保留不经事先通知为维修保养、升级或其它目的暂停全部或部分的网络服务的权利
    </p>
    <p>
      13、本公司有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，
      本公司对用户和任何第三人均无需承担任何责任。
    </p>
    <p><strong>五、服务使用限制</strong></p>
    <p>
      <strong
        >1、您在使用本服务时应遵守中华人民共和国相关法律法规及您所属、
        所居住或开展经营活动或其他业务的国家或地区的法律法规，
        不得将本服务用于任何非法目的（包括用于禁止或限制交易物品的交易），
        也不得以任何非法方式使用本服务，包括但不限于：</strong
      >
    </p>
    <p><strong>（1） 违反法律或合同约定义务的：</strong></p>
    <p><strong>i. 侵犯第三方的著作权、专利、商标、商业秘密或其它专有权利、公共利益和隐私的；</strong></p>
    <p><strong>ii. 侮辱或者诽谤他人，侵害他人合法权益的；</strong></p>
    <p><strong>iii. 教唆犯罪的；</strong></p>
    <p><strong>iv. 提供赌博信息或以其他方式引诱他人参与赌博的等；</strong></p>
    <p><strong>v. 洗钱、非法套现、传销、贩卖枪支、毒品、禁药、盗版软件、淫秽物品或其他违禁物等；</strong></p>
    <p><strong>vi. 违反依法律或合约所应负之保密义务的；</strong></p>
    <p><strong>vii. 国家法律、行政法规禁止的其他内容。</strong></p>
    <p><strong>（2）为任何非法目的而使用本公司服务的：</strong></p>
    <p><strong>i. 冒用他人名义使用本公司服务的；</strong></p>
    <p><strong>ii. 非法使用他人银行帐号或使用无效银行帐号进行交易的；</strong></p>
    <p><strong>iii. 利用本公司服务进行无真实交易背景的虚假交易的；</strong></p>
    <p>
      <strong
        >iv. 被本公司、发卡银行或收单银行认定为对银行卡滥用的行为，属于违反《银行卡收单业务管理办法》的行为。</strong
      >
    </p>
    <p><strong>（3） 危害计算机信息网络安全的：</strong></p>
    <p><strong>i. 从事任何可能含有电脑病毒或是可能侵害本公司的系统、资料的行为的；</strong></p>
    <p><strong>ii. 故意制作、传播计算机病毒等破坏性程序的；</strong></p>
    <p><strong>iii. 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</strong></p>
    <p><strong>iv. 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</strong></p>
    <p>
      <strong>v. 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</strong>
    </p>
    <p><strong>vi. 其他危害计算机信息网络安全的行为。</strong></p>
    <p><strong>（4）或本公司有正当理由认为您有不适当的其他行为。</strong></p>
    <p>
      <strong
        >2、您若违反本协议或相关的服务条款的规定， 导致或产生的任何第三方主张的任何索赔、要求或损失，
        包括合理的律师费，您同意赔偿本公司与合作公司、关联公司， 并使之免受损害。对此，本公司有权视用户的行为性质，
        采取包括但不限于删除用户发布信息内容、暂停使用许可、终止提供服务、 限制使用、回收用户帐号、追究法律责任等措施。
        对恶意注册用户帐号或利用帐号进行违法活动、 捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，
        本公司有权回收其帐号。同时，本公司会视司法部门的要求，协助调查。</strong
      >
    </p>

    <p><strong>六、责任范围及责任限制</strong></p>
    <p><strong>1、本公司仅对本协议中列明的责任承担范围负责。</strong></p>
    <p>
      <strong
        >（1）本公司并非银行，本协议项下涉及的资金转移均通过银行来实现， 您接受资金在转移途中产生的合理时间。</strong
      >
    </p>
    <p><strong>（2）基于相关法律法规的规定，对本协议项下的服务，我们均无法提供担保、垫资。</strong></p>
    <p>
      <strong
        >（3）我们会将您委托我们代为收取或代为支付的款项，
        严格按照法律法规或有权机关的监管要求进行管理。我们提请您注意，
        联动账户所记录的资金余额不同于您本人的银行存款，不受《存款保险条例》保护，
        其实质为您委托我们保管的、所有权归属于您的预付价值。
        该预付价值对应的货币资金虽然属于您，但不以您本人名义存放在银行，
        而是以我们的名义存放在银行，并且由我们向银行发起资金调拨指令。</strong
      >
    </p>
    <p><strong>2、本公司对服务内容不作任何明示或暗示的保证，包括但不限于以下事项：</strong></p>
    <p><strong>（1）本服务符合您的需求。</strong></p>
    <p><strong>（2）本服务不受干扰、及时提供或免于出错。</strong></p>
    <p><strong>（3）本公司所提交的信息的准确性、合法性和及时性。</strong></p>
    <p><strong>3、您使用本服务从事的交易及时或最终完成。 交易风险提示：</strong></p>
    <p>
      <strong
        >（1）在使用我们的服务时，若您或您的交易对方未遵从本协议或相关网站说明、
        交易、支付页面中的操作提示、规则，则我们有权拒绝为您与交易对方提供服务， 且我们免于承担损害赔偿责任。</strong
      >
    </p>
    <p>
      <strong
        >（2）请您特别注意，如在联动上以页面标明或其他方式表明相关服务系由第三方提供，
        您在使用该服务过程中如有疑问或发生纠纷，请您与第三方协商解决。</strong
      >
    </p>
    <p>
      <strong
        >（3）您使用我们的服务购买金融类产品时，为了您的利益， 建议您仔细阅读金融类产品的协议以及页面提示，
        确认销售机构及产品信息，谨慎评估风险后再购买。</strong
      >
    </p>
    <p>
      <strong
        >4、本公司用户信息是由用户本人自行提供的，本公司无法保证该信息之准确、及时和完整，您应对您的判断承担全部责任。</strong
      >
    </p>
    <p>
      <strong
        >5、您经由本服务之使用下载或取得任何资料，应由您自行考量且自负风险，
        因资料之下载而导致您电脑系统之任何损坏或资料流失，您应负完全责任。</strong
      >
    </p>
    <p>
      <strong
        >6、您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。</strong
      >
    </p>
    <p>
      <strong
        >7、在任何情况下，本公司对于与本协议有关或由本协议引起的任何间接的、 惩罚性的、特殊的、派生的损失
        （包括业务损失、收益损失、利润损失、 商誉损失、使用数据或其他经济利益的损失），不论是如何产生的，
        也不论是由对本协议的违约（包括违反保证）
        还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。</strong
      >
    </p>
    <p>
      <strong
        >8、服务费用。在您使用我们的服务时， 我们有权向您收取服务费用。 我们拥有制订及调整服务费的权利，
        具体服务费用以您使用我们服务时的官方公众号或产品页面上所列的收费方式公告或您与我们达成的其他书面协议为准。
        除非另有说明或约定，
        您同意我们有权自您委托我们代为收取或代为支付的款项或者其他资产中直接扣,除上述服务费用。</strong
      >
    </p>
    <p><strong>七、配套规则和协议</strong></p>
    <p>
      为了您更好地使用联动服务，您还需要阅读并遵守《用户隐私权政策》、
      《营销活动方案》以及本公司公示的其他规则，请您务必审慎阅读、
      充分理解各条款内容，特别是免除或者限制服务方责任的条款。
      前述协议或规则的部分条款被认定无效的，不影响其他内容的效力。
    </p>
    <p><strong>八、知识产权的保护</strong></p>
    <p>
      1、除第三方产品或服务外，平台上全部智力成果， 包括但不限于数据库、软件、著作、照片、录像、音乐、声音及其前述组合，
      软件编译、相关源代码和软件 (包括小应用程序和脚本) 档案、资讯、资料、架构、 页面设计，
      均由本公司或本公司关联企业依法拥有其知识产权， 包括但不限于版权、商标权、专利权、著作权、商业秘密等。
    </p>
    <p>
      2、非经权利人书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站上任何材料或内容。
    </p>
    <p>
      3、您确认，当您同意本协议时， 或您访问本公司网站、平台网站及其相关网站， 或您使用我们提供的任一服务时，
      即不可撤销的授予本公司基于商业宣传目的在本公司网站、
      宣传材料等各推广平台上对您的信息、企业名称、商标、字号等进行使用的权利。
    </p>
    <p>4、尊重知识产权是您应尽的义务，如有违反，您应承担所有损害赔偿责任。</p>
    <p><strong>九、不可抗力</strong></p>
    <p>1、 平台因下列状况导致服务暂停、中断或停止的，本公司不承担违约或赔偿责任：</p>
    <p>
      （1）因自然灾害如洪水、台风、火灾、爆炸、雷电、 地震和风暴等以及社会事件如停电、战争、动乱、恐怖袭击、政府行为、
      国家政策的突然变动和罢工等不可抗力之因素， 造成本公司平台系统障碍不能提供服务的；
    </p>
    <p>（2）黑客攻击；</p>
    <p>（3）网络、电信设备出现故障不能进行数据传输的；</p>
    <p>（4）云服务系统、计算机系统遭到破坏、瘫痪或无法正常使用而导致信息或纪录的丢失；</p>
    <p>（5）电信技术部门调整或故障、网站升级、银行方面、支付渠道的问题等原因而造成的服务中断或者延迟；</p>
    <p>（6）因政府管制而造成的暂时关闭；</p>
    <p>（7）病毒侵袭</p>
    <p>（8）法律法规、政策监管要求；</p>
    <p>（9）其他。</p>
    <p>
      2、本公司需要定期或不定期地对提供平台系统及其相关的设备进行检修或者维护，
      如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断， 本公司无需为此承担任何责任。
      本公司保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
    </p>
    <p>
      3、由于银行、电信运营商系统、线路的故障、调整、升级， 或电力中断/终断，或现有技术能力无法防御的黑客攻击、
      技术故障、 系统升级的问题等原因造成本公司服务无法正常运作或者不能或部分不能提供服务的， 本公司不承担任何责任。
    </p>
    <p><strong>十、授权条款及隐私保护</strong></p>
    <p>
      <strong
        >1、当您访问平台创建账户时，或使用平台上其他服务时， 您需要向本公司主动提供一些信息。
        本公司也会收集/使用一些信息， 本公司也可能会从关联公司和商业合作伙伴获得信息以补充用户自己收集的信息，
        以向您提供更好的服务。 您同意并授权本公司收集/使用信息的范围主要包括：</strong
      >
    </p>
    <p>
      <strong
        >（1）为了完成账户注册并保障平台相关服务的顺利申请， 您授权本公司收集您在平台提供的以下信息，
        包括：您的姓名、手机号、身份证号及照片、
        个人生物识别信息（如本人照片）、地址信息、银行卡卡号及照片等相关个人信息；</strong
      >
    </p>
    <p>
      <strong>
        （2）为便于查询服务状态或历史记录，也为了遵守法律法规的规定， 本公司会保存您使用本平台服务产生的信息，
        并严格按照法律法规的规定对这些信息进行妥善保管；</strong
      >
    </p>
    <p>
      <strong
        >（3）为了充分保护您的账户安全，当您访问本平台时， 或使用本平台上提供的服务时，
        本平台可能会记录您操作的相关信息，包括但不限于您的计算机Ip地址、 设备标识符、硬件型号、操作系统版本、
        您的位置以及与平台服务相关的日志信息， 这些信息可帮助本公司更好地识别用户的身份以及保护您的账户安全，
        例如您的账户在可疑操作环境下登录的， 平台系统可能监控到账户风险，采取一些措施避免用户的资金损失；</strong
      >
    </p>
    <p>
      <strong
        >（4）您同意，当行政机关、司法机关、监管部门、 中国互联网金融协会等行业自律组织要求查询、
        提供、打印、留存您的个人信息时， 本公司有权根据相关部门的要求向其提供您的个人信息；</strong
      >
    </p>
    <p>
      <strong
        >（5）您授权本公司根据服务需要，将收集的您的个人信息提供给合法留存用户信息的自然人、法人以及其他组织进行身份查询和验证；</strong
      >
    </p>
    <p>
      <strong
        >（6）本平台所涉及的增值服务包括但不限于云闪付推广服务或其他增值服务。
        当您使用本平台上第三方公司提供的增值服务的， 该服务会根据第三方公司要求向您收集所需的个人信息
        （包括姓名、身份证号、手机号、银行卡号）。您在此确认， 当您选择使用第三方公司增值服务时，
        您同意并授权本公司可收集您提交的个人信息， 并传递给提供增值服务的第三方公司，以便开通相应服务。
        同时，您知晓并同意，您选择使用第三方增值服务的， 在此过程中本公司将留存您提交的个人信息，
        若您不选择使用本平台上第三方公司的增值服务， 则不会向您收集相应信息。对于增值服务下，本公司所收集、
        留存的信息，本公司将严格按照法律法规的规定进行妥善保管。</strong
      >
    </p>
    <p>
      <strong
        >除上述信息外，本公司还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，
        包括您与平台的客户服务团队联系时提供的相关信息， 您参与问卷调查时向平台发送的问卷答复信息，
        以及您与平台互动时本公司收集的相关信息。 与此同时，为提高用户使用平台提供的服务的安全性，
        更准确地预防钓鱼网站欺诈和木马病毒， 本公司可能会通过了解一些您的网络使用习惯、
        常用的软件信息等手段来判断用户账户的风险。</strong
      >
    </p>
    <p>
      <strong
        >2、因收集信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的，为了实现这一目的，您授权本公司将您的信息用于下列用途：</strong
      >
    </p>
    <p><strong>（1）向您提供平台的各项服务，并维护、改进这些服务；</strong></p>
    <p>
      <strong
        >（2）比较信息的准确性，并与第三方进行验证。例如，将您向平台提交的身份信息与身份验证的服务机构进行核验；</strong
      >
    </p>
    <p>
      <strong>（3）为使用户知晓自己使用平台服务的情况或了解平台的服务，向您发送服务状态的通知、营销活动信息；</strong>
    </p>
    <p><strong>（4）预防或禁止非法的活动；</strong></p>
    <p><strong>（5）经您许可的其他用途。</strong></p>
    <p>
      <strong
        >3、为保障您的信息安全，本公司努力采取各种合理的物理、 电子和管理方面的安全措施来保护您的信息，
        使您的信息不会被泄漏、毁损或者丢失， 包括但不限于SSL、信息加密存储、数据中心的访问控制。
        本公司对可能接触到您的信息的员工、 外包人员及合作机构也采取了严格管理，
        包括但不限于根据岗位的不同采取不同的权限控制， 与他们签署保密协议，监控他们的操作情况等措施。
        本公司会按现有技术提供相应的安全措施来保护您的信息， 提供合理的安全保障，
        平台将在任何时候尽力做到使用户的信息不被泄漏、 毁损或丢失。 除本协议另有约定外，未经您的同意，
        本公司不会将您的个人信息转让、披露给任何公司、 组织或个人。</strong
      >
    </p>
    <p><strong>4、更详细内容可参见联动优势《用户隐私政策》。</strong></p>
    <p><strong>十一、服务暂停或终止</strong></p>
    <p>
      <strong
        >1、您同意，本公司有权自行全权决定以任何理由不经事先通知的中止、 终止向您提供部分或全部服务，
        暂时冻结或永久冻结（注销） 您的帐号在联动平台的权限， 且无须为此向您或任何第三方承担任何责任。</strong
      >
    </p>
    <p><strong>2、出现以下情况时，本公司有权直接注销帐号、终止提供服务、终止本协议：</strong></p>
    <p><strong>（1）本公司有合理的依据证明您已经违反本服务协议的规定；或</strong></p>
    <p><strong>（2）本公司发现您有异常交易或发现您的交易涉嫌违法时；或</strong></p>
    <p><strong>（3）在特殊情况下，本公司认为必要之时；或</strong></p>
    <p><strong>（4）本公司发现您有违法、违规行为的；或</strong></p>
    <p><strong>（5） 其它本公司认为应当终止服务的情况。</strong></p>
    <p><strong>3、您同意，您与本公司的协议终止后，本公司仍享有下列权利：</strong></p>
    <p><strong>（1）继续保存您的用户信息及您使用服务期间的所有记录及交易信息。</strong></p>
    <p>
      <strong
        >（2）您在使用服务期间存在违法行为或违反本协议和/或规则的行为的，本公司仍可依据本协议向您主张权利。</strong
      >
    </p>
    <p>
      <strong
        >4、本公司在有合理理由怀疑您进行了本条规定的违约行为时， 有权对您的帐号进行调查。您理解并同意，在调查期间，
        本公司有权限制您所使用的产品或服务的部分或全部功能，
        且无需事先通知。如果调查结果证实您的帐号确实存在上述违约行为， 本公司有权冻结您的帐号并终止与您的合作。
        本公司不对因此导致的任何损害赔偿承担责任， 包括但不限于利润、商誉、使用、
        数据等方面的损失或其他无形损失的损害赔偿 （无论本公司是否已被告知该等损害赔偿的可能性）。</strong
      >
    </p>
    <p>
      <strong
        >5、本公司中止或终止向您提供服务后，对于您在服务中止或终止之前的一切行为，
        您应独力处理并完全承担进行处理所产生的任何争议、 损失或增加的任何费用，
        并应确保本公司免于因此产生任何损失或承担任何费用， 相应损失及费用将由您承担。</strong
      >
    </p>
    <p><strong>十二、广告</strong></p>
    <p>
      1、您同意本公司可以自行或由第三方通过本软件向您发送、 展示广告或其他信息（包括商业与非商业信息），
      广告或其他信息的具体发送及展示形式、频次及内容等以本公司实际提供为准。
    </p>
    <p>
      2、本公司将依照相关法律法规要求开展广告业务。您同意，对本软件中出现的广告，您应审慎判断其真实性和可靠性，
      除法律明确规定外，您应对因该广告而实施的行为负责。
    </p>
    <p><strong>十三、附属规则</strong></p>
    <p>
      本协议针对部分功能以附属规则的形式进行约定，相关附属规则附后。
      附属规则是本协议不可分割的组成部分，具有同等法律效力。 本协议条款与附属规则不一致的，以附属规则内容为准。
    </p>
    <p><strong>十四、法律适用、管辖与其他事项</strong></p>
    <p>1、本协议自用户勾选或点击接受并同意之日起生效，有效期至本协议被解除或终止之日。</p>
    <p>2、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
    <p>
      <strong
        >3、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均应向北京市西城区人民法院提起诉讼。</strong
      >
    </p>
    <p>4、本公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
    <p>5、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    <p><strong>十五、注意事项</strong></p>
    <p>
      <strong
        >切勿进行套现等违法违规操作，不当的行为最终将导致您承担相应的经济损失和法律责任。
        套现是指本人或帮助他人进行无任何实际交易的刷卡行为，目的是获取信用卡里的现金，
        在我国套现是一种违法行为，也是银行机构及公安机关重点打击的经济类犯罪之一</strong
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.content-box {
  margin: 30px;
  padding-bottom: 30px;
}
</style>
